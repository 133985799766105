import { Component, OnInit } from '@angular/core';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import { ConsoleRouterService } from '@console/shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'chaka-console';

  constructor(
    public profile: AuthProfileStateService,
    private router: ConsoleRouterService,
  ) {}

  ngOnInit(): void {
    console.log('is init');
    this.router.loadRouting();
    // this.role.getRole();
    this.profile.getProfile();
  }
}
