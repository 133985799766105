import { CURRENCY } from './../../../../../shared/src/lib/_enums/currency.enum';
import { extractSearchParams } from './../../../../../shared/src/lib/utils/extractSearchParams';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, tap, takeUntil, map } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import {
  ConsoleRouterService,
  ORDER_SIDE,
  ORDER_STATUS,
  ORDER_TYPE,
} from '../../../../../shared/src/public-api';
import { OrderTxnsModel } from '../services/orders.model';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { exportAndDownload, fileNameFormatter } from 'projects/_shared/csv-downloader/csv-downloader';
import { OrderListState, OrderListStateService } from '../orders-list/orders-state';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MerchantDetailStateService } from 'src/app/dashboard/merchant-details.state';
import { MerchantsModel } from '../../merchants/merchants.interface';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import { MerchantListState, MerchantListStateService } from '../../merchants/merchant-list/merchant-list.state';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
  providers: [MerchantListStateService]
})
export class OrdersTableComponent implements OnInit, OnDestroy {
  @Input() userId: string;

  count = 0;

  dataSource;

  /**
   * loading controller
   */
  loading = false;

  /**
   * Query emitter to parent component
   */
  @Output() query = new EventEmitter<object>();

  /**
   * emit query selected from the table
   */
  @Output() order = new EventEmitter<OrderTxnsModel>();

  orderSides = [
    {
      name: 'All',
      value: ORDER_SIDE.ALL,
    },
    {
      name: 'Buy',
      value: ORDER_SIDE.BUY,
    },
    {
      name: 'Sell',
      value: ORDER_SIDE.SELL,
    },
  ];

  orderTypes = [
    {
      name: 'All',
      value: ORDER_TYPE.ALL,
    },
    {
      name: 'Market',
      value: ORDER_TYPE.MARKET,
    },
    {
      name: 'Limit',
      value: ORDER_TYPE.LIMIT,
    },
    {
      name: 'Stop',
      value: ORDER_TYPE.STOP,
    },
  ];

  orderStatus = [
    {
      name: 'All',
      value: ORDER_STATUS.ALL,
    },
    {
      name: 'New',
      value: ORDER_STATUS.NEW,
    },
    {
      name: 'Pending',
      value: ORDER_STATUS.PENDING,
    },
    {
      name: 'Pending Cancel',
      value: ORDER_STATUS.PENDING_CANCEL,
    },
    {
      name: 'Filled',
      value: ORDER_STATUS.FILLED,
    },
    {
      name: 'Partially Filled',
      value: ORDER_STATUS.PARTIALLY_FILLED,
    },
    {
      name: 'Rejected',
      value: ORDER_STATUS.REJECTED,
    },
    {
      name: 'Cancelled',
      value: ORDER_STATUS.CANCELED,
    },
  ];

  marketTypes: { name: string, value: string }[] = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Local',
      value: CURRENCY.NAIRA
    },
    {
      name: 'Global',
      value: CURRENCY.DOLLAR
    }
  ];

  @Output() batch = new EventEmitter<OrderTxnsModel[]>();

  // private allChecked = false;

  checked: Record<string, boolean> = {};

  // here
  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'createdBy',
    'reference',
    'type',
    'orderSides',
    'symbol',
    'market',
    'totalAmount',
    'chakaFees',
    'quantity',
    'status',
    'dateCreated'
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  private filter: Record<string, any> = {};

  formatToCurrency = formatToCurrency;

  selectedItems = new Map();

  componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChild(MatSort) sort: MatSort;

  btnLoading = {
    reQuery: false,
    cancel: false,
    downloadAll: false,
    positions: false
  };

  orderErrorsId: { requery: number, cancel: number } = {
    requery: 0,
    cancel: 0
  };

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[],
    loading: boolean,
    total: number,
    pageSize: number,
    pageNumber: number
  } = {
      list: [],
      loading: false,
      total: 0,
      pageSize: 1000,
      pageNumber: 0
    };

  constructor(
    private consoleRouter: ConsoleRouterService,
    public orderList: OrderListStateService,
    private role: AuthRoleStateService,
    private messagesService: MessagesService,
    public merchant: MerchantDetailStateService,
    public authProfile: AuthProfileStateService,
    public merchantListState: MerchantListStateService
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      q: new FormControl(''),
      type: new FormControl(''),
      side: new FormControl(''),
      status: new FormControl(''),
      currency: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl('')
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues)
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.orderList.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: OrderListState) => {
          this.dataSource = new MatTableDataSource<OrderTxnsModel>(state.orders);
          this.dataSource.sort = this.sort;
          this.count = state.count;
          this.loading = state.loading;
        }
      );
  }

  listenOnAuthProfile(): void {
    this.authProfile.isSuperAdmin$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((isSuperAdmin) => isSuperAdmin)
      )
      .subscribe((isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;

        this.isSuperAdmin ?
          this.merchantListState.find({
            pageSize: this.merchants.pageSize,
            pageNumber: this.merchants.pageNumber
          }) :
          null;
      });

  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe(
        (state: MerchantListState) => {
          this.merchants.loading = state?.loading;
          this.merchants.total = state?.count;

          if (state.merchants.length > 1) {
            this.merchants.list = [...this.merchants.list, ...state?.merchants];
          }

        }
      );
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.query.emit(this.filter);
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach(order => {
      this.updateCheckedSet(checked, order);
    });
  }

  onItemChecked(checked: boolean, order: OrderTxnsModel): void {
    this.updateCheckedSet(checked, order);
  }

  updateCheckedSet(checked: boolean, order: OrderTxnsModel): void {
    if (checked) {
      this.selectedItems.set(order.id, order);
    } else {
      this.selectedItems.delete(order.id);
    }
  }

  get selectedOrders(): OrderTxnsModel[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Orders',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedOrders);
  }


  reQuerySelected(): void {

    try {
      const notAllowed = [
        ORDER_STATUS.REJECTED,
        ORDER_STATUS.FILLED,
        ORDER_STATUS.CANCELED
      ];

      const cannotRequery = this.selectedOrders
        .some(({ status }) => [ORDER_STATUS.ALL, ...notAllowed].includes(status));

      if (cannotRequery) {
        throw new Error(`You CANNOT re-query orders with status - ${notAllowed.join(', ')}.`);
      }

      if (this.selectedItems.size > 10) {
        throw new Error('You can only re-query a maximum of 10 orders');
      }

      this.btnLoading.reQuery = true;

      const orderIds = this.selectedOrders.map(({ orderId }) => orderId);

      this.orderList.batchReQuery(
        orderIds,
        this.filter,
        (success: boolean) => {
          this.btnLoading.reQuery = false;
          if (success) {
            this.selectedItems.clear();
          }
        });

    } catch (error) {
      !!this.orderErrorsId.requery && this.messagesService.hide(this.orderErrorsId.requery);
      const { id } = this.messagesService.open(MessagesEnum.danger, error.message);
      this.orderErrorsId.requery = id;
    }
  }

  cancelSelected(): void {
    try {
      const cannotCancel = this.selectedOrders
        .some(({ status }) => status !== ORDER_STATUS.PENDING);

      if (cannotCancel) {
        throw new Error('You can ONLY cancel PENDING orders.');
      }

      if (this.selectedItems.size > 10) {
        throw new Error('You can only cancel a maximum of 10 orders');
      }

      const check = confirm(`Do you want to cancel ${this.selectedItems.size === 1 ? 'this order' : 'these orders'}? `);

      if (check) {
        this.btnLoading.cancel = true;
        const orderIds = this.selectedOrders.map(({ orderId }) => orderId);

        this.orderList.batchCancel(
          orderIds,
          this.filter,
          (success: boolean) => {
            this.btnLoading.cancel = false;
            success && this.selectedItems.clear();
          }
        );
      }

    } catch (error) {
      !!this.orderErrorsId.cancel && this.messagesService.hide(this.orderErrorsId.cancel);
      const { id } = this.messagesService.open(MessagesEnum.danger, error.message);
      this.orderErrorsId.cancel = id;
    }
  }

  get canUpdateOrder$(): Observable<boolean> {
    const canApprove$ = this.role.hasPermission$('ORDER_UPDATE');

    return canApprove$.pipe(map((canApprove) => canApprove));
  }

  get canDownloadAll$(): Observable<boolean> {
    const allowedMerchantCodes = ['AXA'];

    return this.merchant.state.pipe(
      filter((state) => state?.merchant?.code),
      map((state) => allowedMerchantCodes.includes(state.merchant.code
      ))
    );
  }

  downloadAll(): void {
    this.btnLoading.downloadAll = true;

    this.orderList
      .downloadAll(() => this.btnLoading.downloadAll = false);
  }

  downloadAllPositions(): void {
    this.btnLoading.positions = true;

    this.orderList
      .downloadAllPositions(() => this.btnLoading.positions = false);
  }

  emit(order: OrderTxnsModel): void {
    this.order.emit(order);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();
  }

}
