import { Observable } from 'rxjs';
/**
 * UserKYCService
 *
 * This service manages the user KYc updates only,
 * nothing else, any data retrival should be moved to
 * other services.
 *
 * oyegoke abiodun 02-09-2020.
 */
import { Injectable } from '@angular/core';
import {
  BaseApiService,
  EnterpriseAPIService,
  ReqSuccessResponse,
} from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import {
  ClientUserModel,
  KycUploadModel,
  UserKycUpdate,
} from '../models/user.model';
import { ApproveKyc, FileUpload, FileUploadData } from './interface';

const API_CLIENT_USERS = '/api/v1/users';
const API_KYC_UPLOADS = '/api/v1/uploads';
const API_BVN = '/api/v1/kyc/user/bvn';
const API_KYC = '/api/v1/kyc/user';
const API_KYC_STATUS = '/api/v1/kyc/user/status';

@Injectable()
export class UserKYCService {
  constructor(
    private http: EnterpriseAPIService,
    private baseApi: BaseApiService,
    private loadingService: LoadingService,
  ) {}

  /**
   * handles the upload of documents on KYC.
   */
  uploadUserDocs(
    doc: KycUploadModel,
  ): Observable<ReqSuccessResponse<ClientUserModel>> {
    return this.http.post<ReqSuccessResponse<ClientUserModel>>(
      API_KYC_UPLOADS,
      doc,
    );
  }

  uploadDocument(
    upload: FileUpload,
  ): Observable<ReqSuccessResponse<FileUploadData>> {
    const formdata = new FormData();
    formdata.append('file', upload.file);
    formdata.append('uploadType', upload.uploadType);
    formdata.append('userId', upload.userId);
    return this.http.post<ReqSuccessResponse<FileUploadData>>(
      `/api/v1/uploads`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${this.baseApi.token()}`,
        },
      },
    );
  }

  /**
   * updates the KYC status of a user to a state
   */
  updateKycStatus(kyc: ApproveKyc): Observable<ReqSuccessResponse<string>> {
    const updateKyc$ = this.http.put<ReqSuccessResponse<string>>(
      API_KYC_STATUS,
      kyc,
    );

    return this.loadingService.showLoaderUntilCompleted(updateKyc$);
  }

  /**
   * updates the user BVN details on the KYC
   */
  updateBvnDetails(bvn): Observable<ReqSuccessResponse<unknown>> {
    return this.http.put<ReqSuccessResponse<unknown>>(API_BVN, bvn);
  }

  /**
   * create an inital onboarding for a user before updating
   */
  createUserKyc(
    kyc: Partial<ClientUserModel>,
  ): Observable<ReqSuccessResponse<UserKycUpdate>> {
    const url = `${API_CLIENT_USERS}/onboard`;
    const user$ = this.http.post<ReqSuccessResponse<UserKycUpdate>>(url, kyc);

    return this.loadingService.showLoaderUntilCompleted(user$);
  }

  /**
   * updates the user KYC details
   */
  updateUserKyc(
    kyc: Partial<ClientUserModel>,
  ): Observable<ReqSuccessResponse<UserKycUpdate>> {
    const url = `${API_CLIENT_USERS}/onboard`;
    const user$ = this.http.put<ReqSuccessResponse<UserKycUpdate>>(url, kyc);
    return this.loadingService.showLoaderUntilCompleted(user$);
  }

  iwUpload(userId): Observable<unknown> {
    const url = `${API_KYC}/update-iw-detail/${userId}`;
    const request$ = this.http.put(url);

    return this.loadingService.showLoaderUntilCompleted(request$);
  }

  dwUpload(userId): Observable<unknown> {
    const url = `${API_KYC}/dw-upload/${userId}`;
    const request$ = this.http.post(url);

    return this.loadingService.showLoaderUntilCompleted(request$);
  }

  alpacaUpload(userId: string): Observable<unknown> {
    const url = `${API_KYC}/alpaca-doc-upload/${userId}`;
    const request$ = this.http.post(url);

    return this.loadingService.showLoaderUntilCompleted(request$);
  }
}
