import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { KYC_COUNTRY, KYC_STATUS } from '@console/shared';
import { NotificationService } from '@console/shared/services/notification-service';
import { ClientUserModel, UserKYCService } from '@console/user-managements';
import { BehaviorSubject } from 'rxjs';
import { ApproveKyc } from '../services/interface';

export interface KYCState {
  kyc: Record<KYC_COUNTRY, string>;
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: KYCState = { kyc: {} as any, loading: false };

@Injectable({ providedIn: 'root' })
export class KYCVerificationStateService {
  spinnerName = 'KYC-status-approval-spinner';

  state = new BehaviorSubject<KYCState>(initialState);

  constructor(
    private kycSvc: UserKYCService,
    private notificationService: NotificationService,
  ) {}

  presetKyc(user: ClientUserModel): void {
    if (user && user.userKyc) {
      const kycState: Record<KYC_COUNTRY, string> = {} as any;
      for (const kyc of user.userKyc) {
        kycState[kyc.country] = kyc.status;
      }
      this.state.next({
        ...this.state.getValue(),
        kyc: kycState,
        loading: false,
      });
    }
  }

  approve(approval: ApproveKyc): void {
    // this.loading();
    this.kycSvc.updateKycStatus(approval).subscribe({
      next: this.onKYCLoaded.bind(this)(
        approval.kycCountry,
        approval.kycStatus,
      ),
      error: this.onKYCError.bind(this),
    });
  }

  clearMessage(): void {
    const state = this.state.getValue();
    delete state.message;
    this.state.next(state);
  }

  clearError(): void {
    const state = this.state.getValue();
    delete state.error;
    this.state.next(state);
  }

  reset(): void {
    this.state.next(initialState);
  }

  private onKYCLoaded(
    country: KYC_COUNTRY,
    status: KYC_STATUS,
  ): ({}: ReqSuccessResponse<string>) => void {
    return ({}: ReqSuccessResponse<string>) => {
      console.log('here', country, status);

      const message = `KYC status for ${country} was successfully changed to ${status}`;

      const kyc = this.state.getValue().kyc;
      kyc[country] = status;
      this.state.next({ kyc, loading: false, message });

      this.notificationService.success(message);
    };
  }

  iwUpload(userId: string): void {
    this.kycSvc.iwUpload(userId).subscribe({
      next: (response) => {
        console.log(response);
        this.notificationService.success(
          'IW user details was updated successfully',
        );
      },
      error: this.onKYCError.bind(this),
    });
  }

  dwUpload(userId: string): void {
    this.kycSvc.dwUpload(userId).subscribe({
      next: () => {
        this.notificationService.success('Operation was successful');
      },
      error: this.onKYCError.bind(this),
    });
  }

  alpacaUpload(userId: string): void {
    this.kycSvc.alpacaUpload(userId).subscribe({
      next: () => {
        this.notificationService.success('Operation was successful');
      },
      error: this.onKYCError.bind(this),
    });
  }

  // tslint:disable-next-line:typedef
  private onKYCError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);

    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error,
      message: error,
    });

    this.notificationService.error(error);
  }
}
