import {
  CSCS_UPLOAD_STATUS,
  GENDER,
  KYC_COUNTRY,
  KYC_STATUS,
  KYC_UPLOADS,
  KYC_VALIDATION_STATUS,
} from '@console/shared';

export interface ClientUserModel {
  id?: number;
  userId?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  address: string;
  country: string;
  gender: GENDER;
  maritalStatus: string;
  dob: string;
  bankAcctNo: string;
  bankAcctName: string;
  bankId: number;
  bvn: string;
  pepStatus: boolean;
  pepNames: string;
  employmentStatus: string;
  employmentCompany: string;
  employmentPosition: string;
  employedByBroker: boolean;
  employmentType: string;
  directorOfPublicCo: string;
  profileImageUrl: string;
  addressProofUrl: string;
  signatureUrl: string;
  photoIdUrl: string;
  lg: string;
  postalCode: string;
  maidenName: string;
  nextOfKin: string;
  nextOfKinPhone: string;
  investmentObjective: string;
  investmentExperience: string;
  riskTolerance: string;
  disclosureName: string;
  networthLiquid: string;
  networthTotal: string;
  annualIncome: string;
  disabled: boolean;
  phoneVerified: boolean;
  photoIdFileName: string;
  addressProofFileName: string;
  profileImageFilename: string;
  nationality: string;
  dwUserId: string;
  merchantCode?: string;
  portfolioType?: string;
  merchantId?: number;
  fullName?: string;
  title?: string;
  state?: string;
  city?: string;
  email?: string;
  userKyc: UserKycModel[];
  accountLocked: boolean | null;
  hvu: boolean | null;
  accountManagerId: number;
  uploadedAlpacaDocs: boolean;
}

export type UserKycUpdate = Omit<ClientUserModel, 'userKyc'>;

export interface KycUploadModel {
  file: File;
  userId: string;
  uploadType: KYC_UPLOADS;
}

export interface UserKycModel {
  country: KYC_COUNTRY;
  id: number;
  status: KYC_STATUS;
  userId: string;
  validations: UserKycValidations[];
}

export interface UserKycValidations {
  id: number;
  kyc: string;
  status: KYC_VALIDATION_STATUS;
  userId: string;
}

export interface CountryModel {
  name: string;
  code: string;
}

export interface CscsModel {
  id?: number;
  userId: string;
  cscsNumber: string;
  cscsCHN: string;
  cscsSent: boolean;
  uploadStatus: CSCS_UPLOAD_STATUS;
}

export interface UserKycStatus {
  userId: string;
  kycStatus: string;
  kycCountry: string;
}

export interface ICscsLogin {
  cscsUsername: string;
  cscsPassword: string;
}
